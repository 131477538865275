const CryptoJS = require('crypto-js')
const { passphrase } = require('../config/crypto')

export default class Crypto {
  static encryptWithAES = text => {
    return CryptoJS.AES.encrypt(text, passphrase).toString()
  }

  static decryptWithAES = ciphertext => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase)
    const originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText
  }
}
