import { history } from 'index'
import { checkUser } from './components/CheckDefaulterUser/check_user'
import { currentAccount } from './services/firebase'

// urls que não precisam de checagem
const urlsNotCheckUser = ['/auth/login', '/settings']

// intervalos aonde o script rodará completo
const rangeInterval = [
  ['10:00', '11:00'],
  ['13:00', '14:00'],
  ['15:00', '16:00'],
  ['19:00', '20:00'],
  ['22:00', '23:00'],
]

// checa o intervalo pra executar o script
function checkInterval(msDay) {
  let insideInterval = 0
  for (let index = 0; index < rangeInterval.length; index++) {
    const arrInitInterval = rangeInterval[index][0].split(':')
    const arrEndInterval = rangeInterval[index][1].split(':')

    const msInitInterval = new Date(
      new Date().setHours(arrInitInterval[0], arrInitInterval[1], 0, 0),
    ).getTime()
    const msEndInterval = new Date(
      new Date().setHours(arrEndInterval[0], arrEndInterval[1], 0, 0),
    ).getTime()
    insideInterval += msDay >= msInitInterval && msDay <= msEndInterval ? 1 : 0
  }

  return insideInterval
}

// executa o script a cada X minutos
export async function run() {
  setInterval(async () => {
    if (urlsNotCheckUser.includes(history.location.pathname)) {
      return
    }

    const insideInterval = checkInterval(new Date().getTime())
    if (!insideInterval) {
      return
    }

    const user = await currentAccount()

    await checkUser(user.email)
  }, 600000)
}
