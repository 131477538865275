import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  CHAT_RECENTLIST,
  CHAT_CLOSEDLIST,
  CHAT_BOTLIST,
  CHAT_SEARCHLIST,
  CHAT_SENTLIST,
  CHAT_PENDINGLIST,
  CHAT_SECTORLIST,
  CHAT_ACTIVELIST,
  CHAT_SEARCHQUERY,
  PLAY_SOUND,
} from './constants'

export const chatUser = () => ({
  type: CHAT_USER,
})

export const activeUser = (userId, chatInfo, lastDoc) => ({
  type: ACTIVE_USER,
  payload: { userId, chatInfo, lastDoc },
})
export const setSearchQuery = (searchQuery, searchUser, searchStatus, searchMySectors) => ({
  type: CHAT_SEARCHQUERY,
  payload: { searchQuery, searchUser, searchStatus, searchMySectors },
})

export const setFullUser = fullUser => ({
  type: FULL_USER,
  payload: fullUser,
})

export const setPlaySound = value => ({
  type: PLAY_SOUND,
  payload: value,
})

export const addLoggedinUser = userData => ({
  type: ADD_LOGGED_USER,
  payload: userData,
})

export const createGroup = groupData => ({
  type: CREATE_GROUP,
  payload: groupData,
})
export const setRecentChatList = (list, limit) => ({
  type: CHAT_RECENTLIST,
  payload: { list, limit },
})
export const setSectorChatList = (list, limit) => ({
  type: CHAT_SECTORLIST,
  payload: { list, limit },
})
export const setPendingChatList = (list, limit) => ({
  type: CHAT_PENDINGLIST,
  payload: { list, limit },
})
export const setClosedChatList = (list, limit) => ({
  type: CHAT_CLOSEDLIST,
  payload: { list, limit },
})
export const setBotChatList = (list, limit) => ({
  type: CHAT_BOTLIST,
  payload: { list, limit },
})
export const setSentChatList = (list, limit) => ({
  type: CHAT_SENTLIST,
  payload: { list, limit },
})
export const setSearchChatList = (list, limit) => ({
  type: CHAT_SEARCHLIST,
  payload: { list, limit },
})
export const setActiveChatList = (list, limit) => ({
  type: CHAT_ACTIVELIST,
  payload: { list, limit },
})
