import actions from './actions'

const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  authorized: false,
  loading: false,
  loadingPayments: false,
  payments: [],
  superlogicaFields: {},
  userAuth: null,
  userData: null,
  waitingPayment: false,
}

export default function userReducer(state = initialState, action) {
  // console.log('userReducer', action)
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATE_USER:
      // console.log('UPDATE_USER', action.payload)
      return {
        ...state,
        userAuth: action.payload.userAuth,
        userData: action.payload.userData,
      }
    default:
      return state
  }
}
