import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  API_FAILED,
} from './constants'

import { getLoggedInUser } from '../../utils/authUtils'

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
}

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true }
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null }

    case REGISTER_USER:
      return { ...state, loading: true }
    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null }

    case LOGOUT_USER: {
      const { clear } = localStorage
      clear()
      return {}
    }

    case FORGET_PASSWORD:
      return { ...state, loading: true }
    case FORGET_PASSWORD_SUCCESS:
      return { ...state, passwordResetStatus: action.payload, loading: false, error: null }

    case API_FAILED:
      return { ...state, loading: false, error: action.payload }

    default:
      return { ...state }
  }
}

export default Auth
