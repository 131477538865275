import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { history } from 'index'
import { useClearCache } from 'react-clear-cache'
import styles from './style.module.scss'

const language = require('components/ChangeLanguage').getMessage('topbar/profilemenu')

const mapStateToProps = ({ user }) => ({ user })

const roleEnum = {
  basic: 'Basic',
  pro: 'Pro',
  enterprise: 'Enterprise',
  seller: 'Vendas',
  partner: 'Parceiro',
  admin: 'Admin',
  attendant: 'Atendente',
  support: 'Suporte',
  'late-payment': 'Inadimplente',
}

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(0)
  const { emptyCacheStorage } = useClearCache()

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
    emptyCacheStorage()
    history.push('/auth/login')
  }

  const addCount = () => {
    setCount(count)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          {language.topbar_profilemenu_hello}, {user.name || 'Anonymous'}
        </strong>
        <div>
          <strong className="mr-1">{language.topbar_profilemenu_billingplan}: </strong>
          {roleEnum[user.role]}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>{language.topbar_profilemenu_email}: </strong>
          {user.email}
          <br />
          <strong>{language.topbar_profilemenu_phone}: </strong>
          {user.phone || '— '}
        </div>
      </Menu.Item>
      {user.role === 'board' && (
        <Menu.Item>
          <Link to="/telemetry">
            <i className="fe fe-bar-chart-2 mr-2" />
            {language.topbar_profilemenu_telemetry}
          </Link>
        </Menu.Item>
      )}
      {user.role === 'board' && (
        <Menu.Item>
          <Link to="/sellers">
            <i className="fe fe-users mr-2" />
            {language.topbar_profilemenu_sellers}
          </Link>
        </Menu.Item>
      )}
      {user.role === 'board' && (
        <Menu.Item>
          <Link to="/sales">
            <i className="fe fe-users mr-2" />
            Vendas por Período
          </Link>
        </Menu.Item>
      )}
      {user.role === 'board' && (
        <Menu.Item>
          <Link to="/sending">
            <i className="fa fa-whatsapp mr-2" />
            {language.topbar_profilemenu_sending}
          </Link>
        </Menu.Item>
      )}
      {user.role === 'board' && (
        <Menu.Item>
          <Link to="/sending-instagram">
            <i className="fe fe-instagram mr-2" />
            {language.topbar_profilemenu_sending}
          </Link>
        </Menu.Item>
      )}
      {user.role === 'board' && (
        <Menu.Item>
          <Link to="/scrapers">
            <i className="fe fe-instagram mr-2" />
            {language.topbar_profilemenu_scrapers}
          </Link>
        </Menu.Item>
      )}
      {user.role === 'seller' || user.role === 'partner' ? (
        <Menu.Item>
          <Link to="/salespeople">
            <i className="fe fe-user mr-2" />
            {language.topbar_profilemenu_seller}
          </Link>
        </Menu.Item>
      ) : (
        ''
      )}
      {user.role === 'seller' || user.role === 'partner' ? (
        <Menu.Item>
          <Link to="/pending">
            <i className="fe fe-alert-triangle mr-2" />
            {language.topbar_profilemenu_pending}
          </Link>
        </Menu.Item>
      ) : (
        ''
      )}
      {user.role === 'seller' || user.role === 'partner' ? (
        <Menu.Item>
          <Link to="/canceled">
            <i className="fe fe-user-x mr-2" />
            {language.topbar_profilemenu_canceled}
          </Link>
        </Menu.Item>
      ) : (
        ''
      )}
      {user.role === 'seller' || user.role === 'partner' ? (
        <Menu.Item>
          <Link to="/received">
            <i className="fe fe-dollar-sign mr-2" />
            {language.topbar_profilemenu_received}
          </Link>
        </Menu.Item>
      ) : (
        ''
      )}
      <Menu.Divider />
      {user.role === 'basic' ||
      user.role === 'pro' ||
      user.role === 'pro2' ||
      user.role === 'enterprise' ||
      user.role === 'board' ||
      user.role === 'support' ||
      user.role === 'partner' ||
      user.role === 'facebook' ||
      user.role === 'seller' ? (
        <Menu.Item>
          <Link to="/company">
            <i className="fe fe-flag mr-2" />
            {language.topbar_profilemenu_editcompany}
          </Link>
        </Menu.Item>
      ) : (
        ''
      )}
      {user.role === 'basic' ||
      user.role === 'pro' ||
      user.role === 'pro2' ||
      user.role === 'enterprise' ||
      user.role === 'board' ||
      user.role === 'support' ||
      user.role === 'partner' ||
      user.role === 'facebook' ||
      user.role === 'seller' ? (
        <Menu.Item>
          <Link to="/exports">
            <i className="fe fe-log-out mr-2" />
            {language.topbar_profilemenu_exportscompany}
          </Link>
        </Menu.Item>
      ) : (
        ''
      )}
      {user.role === 'basic' ||
      user.role === 'pro' ||
      user.role === 'pro2' ||
      user.role === 'enterprise' ||
      user.role === 'seller' ||
      user.role === 'support' ||
      user.role === 'partner' ||
      user.role === 'facebook' ||
      user.role === 'late-payment' ||
      user.role === 'attendant' ||
      user.role === 'attendantbasic' ||
      user.role === 'board' ? (
        <Menu.Item>
          <Link to="/settings">
            <i className="fe fe-settings mr-2" />
            {language.topbar_profilemenu_editprofile}
          </Link>
        </Menu.Item>
      ) : (
        ''
      )}
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          {language.topbar_profilemenu_logout}
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar className={styles.avatar} size="medium" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
