export const CHAT_USER = 'CHAT_USER'
export const ACTIVE_USER = 'ACTIVE_USER'
export const FULL_USER = 'FULL_USER'
export const ADD_LOGGED_USER = 'ADD_LOGGED_USER'
export const CREATE_GROUP = 'CREATE_GROUP'
export const CHAT_LOADED_OPEN = 'CHAT_LOADED_OPEN'
export const CHAT_RECENTLIST = 'CHAT_RECENTLIST'
export const CHAT_CLOSEDLIST = 'CHAT_CLOSEDLIST'
export const CHAT_PENDINGLIST = 'CHAT_PENDINGLIST'
export const CHAT_SECTORLIST = 'CHAT_SECTORLIST'
export const CHAT_ACTIVELIST = 'CHAT_ACTIVELIST'
export const CHAT_BOTLIST = 'CHAT_BOTLIST'
export const CHAT_SENTLIST = 'CHAT_SENTLIST'
export const CHAT_SEARCHLIST = 'CHAT_SEARCHLIST'
export const CHAT_SEARCHQUERY = 'CHAT_SEARCHQUERY'
export const PLAY_SOUND = 'PLAY_SOUND'
