import React from 'react'
import { ConfigProvider, message, notification } from 'antd'
import { IntlProvider } from 'react-intl'

const ConfigNotification = ({ children }) => {
  const configMessage = message.config({
    top: 70,
    duration: 2,
    maxCount: 2,
  })

  const ConfigNotification = notification.config({
    top: 60,
    duration: 3,
  })

  return (
    <ConfigProvider>
      <IntlProvider messages={(ConfigNotification, configMessage)}>{children}</IntlProvider>
    </ConfigProvider>
  )
}

export default ConfigNotification
