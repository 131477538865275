/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import createActivityDetector from 'activity-detector-ssr'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ user }) => ({ user })
const mapDispatchToProps = dispatch => ({ dispatch })
let previousPath = ''

class Layout extends React.Component {
  activityDelay = true

  constructor(props) {
    super(props)
    this.updateActivity = this.updateActivity.bind(this)
  }

  componentDidMount() {
    for (const x in ['click', 'focus', 'keydown', 'mousewheel', 'DOMMouseScroll']) {
      window.addEventListener(x, this.updateActivity)
    }
  }

  componentWillUnmount() {
    for (const x in ['click', 'focus', 'keydown', 'mousewheel', 'DOMMouseScroll']) {
      window.removeEventListener(x, this.updateActivity)
    }
  }

  updateActivity() {
    const { dispatch } = this.props
    if (this.activityDelay) {
      this.activityDelay = false
      setTimeout(() => {
        this.activityDelay = true
      }, 60000)
      dispatch({
        type: 'user/UPDATE_ACTIVITY',
        payload: {},
      })
    }
  }

  render() {
    const {
      user,
      children,
      location: { pathname, search },
    } = this.props
    // NProgress & ScrollTop Management
    const currentPath = pathname + search
    if (currentPath !== previousPath) {
      window.scrollTo(0, 0)
      NProgress.start()
    }
    setTimeout(() => {
      NProgress.done()
      previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/' || /^\/public(?=\/|$)/i.test(pathname)) {
        return 'public'
      }
      if (/^\/auth(?=\/|$)/i.test(pathname)) {
        return 'auth'
      }
      return 'main'
    }

    // auth for demo build, remove it in your app
    // const DEMO_AUTH = process.env.REACT_APP_AUTHENTICATED === 'true'

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isAuthLayout = getLayout() === 'auth'
    const isPublicAccess = getLayout() === 'public'

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
        // console.log('isUserLoading && !isUserAuthorized && !isAuthLayout')
        return null
      }
      // redirect to login page if current is not login page and user not authorized
      if (!isAuthLayout && !isUserAuthorized && !isPublicAccess) {
        // console.log('!isAuthLayout && !isUserAuthorized')
        return <Redirect to="/auth/login" />
      }
      // console.log('<Container>{children}</Container>')
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="Redrive | %s" title="Redrive Apps" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
