/* eslint-disable no-case-declarations */
import { DEFAULT_LIST_LIMIT } from 'config/chat'
import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  CHAT_SEARCHLIST,
  CHAT_RECENTLIST,
  CHAT_CLOSEDLIST,
  CHAT_PENDINGLIST,
  CHAT_SECTORLIST,
  CHAT_SEARCHQUERY,
  CHAT_SENTLIST,
  CHAT_BOTLIST,
  CHAT_ACTIVELIST,
  PLAY_SOUND,
} from './constants'

const INIT_STATE = {
  recentList: null,
  closedList: null,
  botList: null,
  sentList: null,
  sectorList: null,
  pendingList: null,
  searchList: null,
  recentListLimit: DEFAULT_LIST_LIMIT,
  closedListLimit: DEFAULT_LIST_LIMIT,
  botListLimit: DEFAULT_LIST_LIMIT,
  sentListLimit: DEFAULT_LIST_LIMIT,
  sectorListLimit: DEFAULT_LIST_LIMIT,
  pendingListLimit: DEFAULT_LIST_LIMIT,
  searchListLimit: DEFAULT_LIST_LIMIT,
  active_user: null,
  activeUserInfo: null,
  playSound: true,
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_RECENTLIST: {
      if (!action.payload.limit) {
        action.payload.limit = state.recentListLimit
      }
      // console.log('action.payload recent', action.payload)
      return {
        ...state,
        recentList: action.payload.list,
        recentListLimit: action.payload.limit,
      }
    }
    case CHAT_CLOSEDLIST: {
      if (!action.payload.limit) {
        action.payload.limit = state.closedListLimit
      }
      // console.log('action.payload closed', action.payload)
      return {
        ...state,
        closedList: action.payload.list,
        closedListLimit: action.payload.limit,
      }
    }
    case CHAT_SENTLIST: {
      if (!action.payload.limit) {
        action.payload.limit = state.sentListLimit
      }
      // console.log('action.payload bot', action.payload)
      return {
        ...state,
        sentList: action.payload.list,
        sentListLimit: action.payload.limit,
      }
    }
    case CHAT_BOTLIST: {
      if (!action.payload.limit) {
        action.payload.limit = state.botListLimit
      }
      // console.log('action.payload bot', action.payload)
      return {
        ...state,
        botList: action.payload.list,
        botListLimit: action.payload.limit,
      }
    }
    case CHAT_PENDINGLIST: {
      if (!action.payload.limit) {
        action.payload.limit = state.pendingListLimit
      }
      // console.log('action.payload pending', action.payload)
      return {
        ...state,
        pendingList: action.payload.list,
        pendingListLimit: action.payload.limit,
      }
    }
    case CHAT_SECTORLIST: {
      if (!action.payload.limit) {
        action.payload.limit = state.sectorListLimit
      }
      // console.log('action.payload sector', action.payload)
      return {
        ...state,
        sectorList: action.payload.list,
        sectorListLimit: action.payload.limit,
      }
    }
    case CHAT_SEARCHLIST: {
      if (!action.payload.limit) {
        action.payload.limit = state.searchListLimit
      }
      // console.log('action.payload sector', action.payload)
      return {
        ...state,
        searchList: action.payload.list,
        searchListLimit: action.payload.limit,
      }
    }
    case CHAT_ACTIVELIST: {
      return {
        ...state,
        activeList: action.payload.list,
      }
    }
    case PLAY_SOUND: {
      // console.log('PLAY_SOUND',action.payload)
      return {
        ...state,
        playSound: action.payload,
      }
    }
    case CHAT_USER:
      return { ...state }

    case ACTIVE_USER:
      // console.log('active_user reducer', action.payload)
      return {
        ...state,
        active_user: action.payload.userId,
        activeUserInfo: action.payload.chatInfo,
        activeUserLastDoc: action.payload.lastDoc,
      }

    case CHAT_SEARCHQUERY:
      // console.log('active_user reducer', action.payload)
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
        searchMySectors: action.payload.searchMySectors,
        searchUser: action.payload.searchUser,
        searchStatus: action.payload.searchStatus,
      }

    case FULL_USER:
      return {
        ...state,
        users: action.payload,
      }

    case ADD_LOGGED_USER:
      const newUser = action.payload
      return {
        ...state,
        users: [...state.users, newUser],
      }

    case CREATE_GROUP:
      const newGroup = action.payload
      return {
        ...state,
        groups: [...state.groups, newGroup],
      }

    default:
      return { ...state }
  }
}

export default Chat
