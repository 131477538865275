import React, { useState, useEffect } from 'react'
import { Dropdown, Badge } from 'antd'
import List2 from 'components/kit/widgets/Lists/2'
import { db, currentAccount } from 'services/firebase'
import styles from './style.module.scss'

const Actions = () => {
  const [notifications, setNotifications] = useState([])
  const [user, setUser] = useState()
  let isFirstLoading = true

  useEffect(() => {
    const fetchData = async () => {
      let currentUser
      if (!user) {
        currentUser = await currentAccount()
        setUser(currentUser.uid)
      }

      const notifRef = db
        .collection('users')
        .doc(user ?? currentUser.uid)
        .collection('notifications')
        .orderBy('createdAt', 'desc')

      if (notifications.length <= 0) {
        const oldNotifications = await notifRef
          .where('createdAt', '<=', new Date(new Date().getTime() - 24 * 60 * 60 * 1000))
          .limit(10)
          .get()

        setNotifications([...oldNotifications.docs])
      }

      const unsubscribe = notifRef
        .where('createdAt', '>=', new Date(new Date().getTime() - 24 * 60 * 60 * 1000))
        .onSnapshot(snapshot => {
          snapshot.docChanges()?.forEach(change => {
            if (change.type === 'modified' && change.doc.data().seen === true) {
              setNotifications(prevNotifications => [
                ...prevNotifications.filter(n => n.id !== change.doc.id),
                change.doc,
              ])
            } else {
              setNotifications(prevNotifications => [change.doc, ...prevNotifications])
            }
          })

          if (isFirstLoading) {
            isFirstLoading = false
          } else if (
            !snapshot.docChanges().some(doc => doc.type === 'modified' && doc.doc.data().seen)
          ) {
            const audio = new Audio('/audio/alert.mp3')
            audio.play()
            setTimeout(() => {
              audio.pause()
            }, 3000)
          }
        })

      return () => {
        unsubscribe()
      }
    }

    fetchData()
  }, [user?.id])

  const menu = (
    <React.Fragment>
      <div className="card cui__utils__shadow width-350 border-0">
        <div className="card-body p-0">
          <List2 notifications={notifications} />
        </div>
      </div>
    </React.Fragment>
  )

  const unread = notifications.filter(not => {
    return !not.data().seen
  })

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <Badge count={unread.length} size="small" color="gold">
          <i className={`${styles.icon} fe fe-bell`} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default Actions
