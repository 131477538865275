import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children }) => {
  const localeCurrent = localStorage.getItem('app.settings.language') || 'pt-br'

  /* NÃO FUNCIONA, dá warngin
  // const localePrefix = localeCurrent.replace(/-(\w{2})$/, (_, region) => `_${region.toUpperCase()}`)
  // const localeAntd = require(`../node_modules/antd/es/locale/${localePrefix}`).default
  */

  let localeAntd = {}
  switch (localeCurrent) {
    case 'en-us':
      localeAntd = require(`../node_modules/antd/es/locale/en_US`).default
      break
    case 'es-es':
      localeAntd = require(`../node_modules/antd/es/locale/es_ES`).default
      break
    default:
      localeAntd = require(`../node_modules/antd/es/locale/pt_BR`).default
      break
  }

  return (
    <ConfigProvider locale={localeAntd}>
      <IntlProvider locale={localeCurrent}>{children}</IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
