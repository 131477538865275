import { useEffect } from 'react'
import hotjar from '@hotjar/browser'
import { useLocation } from 'react-router-dom'

const Hotjar = () => {
  const location = useLocation()

  useEffect(() => {
    hotjar.stateChange(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    hotjar.init(5035539, 6)
  }, [])

  return null
}

export default Hotjar
