/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Tag, Input } from 'antd'
import {
  WhatsAppOutlined,
  YoutubeOutlined,
  MessageOutlined,
  FunnelPlotOutlined,
  IdcardOutlined,
  FacebookOutlined,
  WarningOutlined,
  LinkedinOutlined,
  InstagramOutlined,
  GoogleOutlined,
  WindowsOutlined,
  FlagOutlined,
  SendOutlined,
  PlusOutlined,
  ShareAltOutlined,
  HeartOutlined,
  ExclamationOutlined,
} from '@ant-design/icons'
import style from './style.module.scss'

const language = require('components/ChangeLanguage').getMessage('crm')

class Tags extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputVisible: false,
      inputValue: '',
    }
  }

  componentDidMount() { }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value })
  }

  handleInputConfirm = () => {
    const { onAdd } = this.props
    const { inputValue } = this.state

    if (typeof onAdd === 'function' && inputValue.trim() !== '') {
      onAdd(inputValue.trim())
    }

    this.setState({
      inputVisible: false,
      inputValue: '',
    })
  }

  saveInputRef = input => {
    this.input = input
  }

  render() {
    const { inputVisible, inputValue } = this.state

    const { tags, tagClick, closable, onClose, onAdd, selected } = this.props

    let selectedTags = []
    if (Array.isArray(selected)) selectedTags = selected

    let allowAddNew = false
    if (onAdd) allowAddNew = true

    let onCloseEvent = () => { }
    if (typeof onClose === 'function') {
      onCloseEvent = onClose
    }

    let tagClickEvent = () => { }
    if (typeof tagClick === 'function') {
      tagClickEvent = tagClick
    }

    return (
      <div className={style.tagList}>
        {tags
          ? tags.map(tag => {
            let isClosable = false

            if (closable) isClosable = true

            let color
            let icon
            let tagName = false

            if (tag === 'WhatsApp') {
              icon = <WhatsAppOutlined />
              color = '#25d366'
              isClosable = false
            } else if (tag === 'Redrive') {
              icon = <MessageOutlined />
              color = '#c0251b'
              isClosable = false
            } else if (tag === 'RDStation' || tag === 'RD Station') {
              icon = <IdcardOutlined />
              color = '#15325d'
              isClosable = false
            } else if (tag === 'Pipedrive') {
              icon = <FunnelPlotOutlined />
              color = '#26292c'
              isClosable = false
            } else if (tag === 'Facebook') {
              icon = <FacebookOutlined />
              color = '#4267B2'
              isClosable = false
            } else if (tag === 'Linkedin') {
              icon = <LinkedinOutlined />
              color = '#55acee'
              isClosable = false
            } else if (tag === 'Telegram') {
              icon = <SendOutlined />
              color = '#229ED9'
              isClosable = false
            } else if (tag === 'Instagram') {
              icon = <InstagramOutlined />
              color = '#833ab4'
              isClosable = false
            } else if (tag === 'Google') {
              icon = <GoogleOutlined />
              color = '#dd4b39'
              isClosable = false
            } else if (tag === 'Bing') {
              icon = <WindowsOutlined />
              color = '#008373'
              isClosable = false
            } else if (tag === 'YouTube' || tag === 'You Tube' || tag === 'youtube') {
              icon = <YoutubeOutlined />
              color = '#ff0000'
              isClosable = false
            } else if (tag === 'Bling') {
              icon = <ExclamationOutlined />
              color = '#0BD692' // 0BD591
              isClosable = false
            } else if (tag === 'Hubspot' || tag === 'HubSpot') {
              color = '#fa7820'
              icon = <ShareAltOutlined rotate="180" />
            } else if (tag === 'LinkSeller') {
              color = '#008AD9'
              // icon = <ShareAltOutlined rotate="180" />
            } else if (tag === 'ExclusiveCRM') {
              color = '#464CA0'
              // icon = <ShareAltOutlined rotate="180" />
            } else if (tag === 'ActiveCampaign') {
              color = '#1b54d9'
              icon = <SendOutlined />
              isClosable = false
            } else if (tag === 'CNE') {
              color = '#009c3b'
              icon = <FlagOutlined />
              isClosable = false
              tagName = language.tags_name_CNE
            } else if (tag === 'Lead Frio') {
              color = 'blue'
              tagName = language.tags_name_LeadFrio
            } else if (tag === 'Novo') {
              color = 'geekblue'
              tagName = language.tags_name_Novo
            } else if (tag === 'Assinante de Newsletter') {
              color = 'cyan'
              tagName = language.tags_name_AssinanteNewsletter
            } else if (tag === 'Lido') {
              color = 'lime'
              tagName = language.tags_name_Lido
            } else if (tag === 'Nutrindo') {
              color = 'orange'
              tagName = language.tags_name_Nutrindo
            } else if (tag === 'Qualificado Marketing') {
              color = 'volcano'
              tagName = language.tags_name_QualificadoMarketing
            } else if (tag === 'Oportunidade') {
              color = 'red'
              tagName = language.tags_name_Oportunidade
            } else if (tag === 'Agendado') {
              color = 'purple'
              tagName = language.tags_name_Agendado
            } else if (tag === 'Cliente') {
              color = 'green'
              tagName = language.tags_name_Cliente
            } else if (tag === 'Ex-Cliente' || tag === 'Ex-cliente' || tag === 'ex-cliente') {
              color = 'magenta'
              tagName = language.tags_name_ExCliente
            } else if (tag === 'Indicação') {
              color = '#900C3F'
              icon = <HeartOutlined />
              tagName = language.tags_name_Indicacao
            } else if (tag === 'Sem WhatsApp') {
              color = '#DC143C'
              icon = <WarningOutlined />
              isClosable = false
              tagName = language.tags_name_SemWhatsApp
            } else if (tag === 'Perdido') {
              color = 'gray'
              tagName = language.tags_name_Perdido
            } else if (tag === 'Promotor') {
              color = 'gold'
              tagName = language.tags_name_Promotor
            } else if (tag === 'Detrator') {
              color = '#26292c'
              tagName = language.tags_name_Detrator
            } else if (tag === 'Bloqueado') {
              color = 'black'
              tagName = language.tags_name_Bloqueado
            } else if (tag === 'Neutro') {
              color = 'yellow'
              tagName = language.tags_name_Neutro
            } else {
              color = ''
            }
            return (
              <Tag
                key={`${tag}-${Math.random()}`}
                icon={icon}
                color={color}
                onClick={() => tagClickEvent(tag)}
                closable={isClosable}
                onClose={() => onCloseEvent(tag)}
                selected={selectedTags.includes(tag)}
                className={selectedTags.indexOf(tag) > -1 ? style.tagSelected : ''}
              >
                {tagName ? tagName : tag}
              </Tag>
            )
          })
          : ''}

        {allowAddNew ? (
          <>
            {inputVisible && (
              <Input
                ref={this.saveInputRef}
                type="text"
                size="small"
                className="tag-input"
                value={inputValue}
                onChange={this.handleInputChange}
                onBlur={this.handleInputConfirm}
                onPressEnter={this.handleInputConfirm}
              />
            )}
            {!inputVisible && (
              <Tag className="site-tag-plus" onClick={this.showInput}>
                <PlusOutlined />
                &nbsp;
                {language.tags_button_addTag}
              </Tag>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    )
  }
}
export default Tags
