import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'
import ChangeLanguage, { getMessage } from '../../components/ChangeLanguage'
const message = getMessage('login')

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image'
                ? 'url(/resources/images/bg/manuel-nageli-p60mNTW5glI-unsplash.jpg)'
                : '',
          }}
          //? 'url(/resources/images/bg/pexels-andrea-piacquadio-3771836.min.jpg)'
          // authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
        >
          <div
            className={classNames(`${style.topbar}`, {
              [style.topbarGray]: isGrayTopbar,
            })}
          >
            <div className={style.logoContainer} style={{ flex: '70%' }}>
              <div className={style.logo}>
                <img src="/resources/images/redrive-logo.png" className="mr-2" alt="Redrive" />
              </div>
            </div>
            <div className="d-none d-sm-block" style={{ flex: '20%' }}>
              <span className="mr-2">{message.account_question}</span>
              <br />
              <a href="https://redrive.com.br/planos" className="font-size-16 kit__utils__link">
                {message.header_start}
              </a>
            </div>
            <div style={{ flex: '10%' }}>
              <ChangeLanguage />
            </div>
          </div>
          <div className={style.containerInner}>{children}</div>
          <div className={`${style.containerApps} container`}>
            <div className="row">
              <div className="col-lg-6 text-center">
                <h6>{message.download_to_ios}</h6>
                <img
                  src="/resources/images/qr-code-ios.png"
                  className={style.qrCode}
                  alt="Redrive"
                />
              </div>
              <div className="col-lg-6 text-center">
                <h6>
                  <h6>{message.download_to_android}</h6>
                </h6>
                <img
                  src="/resources/images/qr-code-android.png"
                  className={style.qrCode}
                  alt="Redrive"
                />
              </div>
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
