/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Link } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Button, Menu, message } from 'antd'
import NumberFormat from 'react-number-format'
import ES from 'services/elasticsearch'
import { currentAccount, db } from 'services/firebase'
import Tags from 'components/tags'
// import bodybuilder from 'bodybuilder'
import style from './style.module.scss'

const language = require('components/ChangeLanguage').getMessage('menuprincipal')

class Search extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSearch: false,
      searchText: '',
      resultSearch: null,
      user: '',
      defaultTags: {},
      tagsFilter: [],
    }

    this.showLiveSearch = this.showLiveSearch.bind(this)
    this.hideLiveSearch = this.hideLiveSearch.bind(this)
    this.changeSearchText = this.changeSearchText.bind(this)
    this.handleNode = this.handleNode.bind(this)
    this.addTag = this.addTag.bind(this)
    this.renderDropdownMenu = this.renderDropdownMenu.bind(this)
    this.handleAddTagToUser = this.handleAddTagToUser.bind(this)
    this.cleanFilters = this.cleanFilters.bind(this)
  }

  async componentDidMount() {
    currentAccount().then(user => {
      this.setState({ user })
    })
    let defaultTags = await db
      .collection('config')
      .doc('default-tags')
      .get()
    defaultTags = defaultTags.data()
    this.setState({ defaultTags })
  }

  // eslint-disable-next-line react/sort-comp
  cleanFilters() {
    this.setState({ tagsFilter: [] })
    this.setState({ searchText: '' })
    this.setState({ resultSearch: null })
    // this.getResults()
  }

  // eslint-disable-next-line react/sort-comp
  addTag(tag) {
    const { tagsFilter } = this.state
    // Evita de add tags duplicadas
    if (tagsFilter.indexOf(tag) > -1) return

    tagsFilter.push(tag)
    this.setState({ tagsFilter })
    this.getResults()
  }

  // eslint-disable-next-line react/sort-comp
  showLiveSearch() {
    this.setState({ showSearch: true, tagsFilter: [] })

    setTimeout(() => {
      this.searchInput.focus()
    }, 100)
  }

  hideLiveSearch() {
    this.searchInput.blur()
    this.setState({ showSearch: false, searchText: '' })
  }

  changeSearchText(e) {
    this.setState({ searchText: e.target.value })
  }

  async getResults() {
    const { searchText, user, tagsFilter } = this.state
    if (user) {
      const q = {
        query: {
          bool: {
            must_not: [
              {
                term: {
                  archived: true,
                },
              },
            ],
            must: [
              {
                query_string: {
                  query: `*${searchText}*`,
                  fields: ['firstname', 'lastname', 'phone'],
                },
              },
              { match: { leadOwner: `${user.owner ? user.owner : user.uid}` } },
            ],
          },
        },
      }

      if (tagsFilter && tagsFilter.length > 0)
        tagsFilter.map(tag => q.query.bool.must.push({ match: { tags: tag } }))

      // bodybuilder().query('match', 'firstname', searchText).build()
      const res = await ES(q, 20)
      // console.log(res.data.hits.total.value)
      this.setState({ resultSearch: res })
    }
    // console.log('res:', res)
  }

  handleKeyDown = event => {
    const { showSearch } = this.state
    if (showSearch) {
      const key = event.keyCode.toString()
      if (key === '27') {
        this.hideLiveSearch()
      }
      if (key === '13') {
        this.getResults()
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleNode(node) {
    this.searchInput = node
  }

  // eslint-disable-next-line class-methods-use-this
  async handleAddTagToUser(leadId, tag, leadData) {
    const { resultSearch } = this.state

    const leadTags = []
    if (leadData.tags) {
      leadTags.push(...leadData.tags)
    }

    if (leadTags.indexOf(tag) === -1) {
      leadTags.push(tag)
    }

    await db
      .collection('crm-leads')
      .doc(leadId)
      .update({ tags: leadTags })
      .then(() => {
        const newHits = resultSearch.data.hits.hits.map(hit => {
          const { _id: hitId } = hit
          if (hitId === leadId) {
            return {
              ...hit,
              _source: {
                ...hit._source,
                tags: leadTags,
              },
            }
          }
          return hit
        })
        const newResultSearch = {
          ...resultSearch,
          data: { ...resultSearch.data, hits: { ...resultSearch.data.hits, hits: newHits } },
        }
        this.setState({ resultSearch: newResultSearch })
        message.success(
          `${language.topBar_search_addTag_message_success_1} ${tag} ${language.topBar_search_addTag_message_success_2}`,
          5.5,
        )
      })
      .catch(err =>
        message.error(language.topBar_search_addTag_message_error, 900, console.dir(err)),
      )
    // await this.getResults()
  }

  // eslint-disable-next-line class-methods-use-this
  renderDropdownMenu(document) {
    const { _id, _source } = document
    // console.log({ resultSearch })

    // this.setState({ resultSearch: res })

    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => this.handleAddTagToUser(_id, 'Oportunidade', _source)}>
            {language.topBar_search_result_opportunityLink}
          </div>
        </Menu.Item>
        <div className="dropdown-divider" />
        <Menu.Item>
          <div onClick={() => this.handleAddTagToUser(_id, 'Bloqueado', _source)}>
            {language.topBar_search_result_lockedLink}
          </div>
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    const { showSearch, searchText, resultSearch, defaultTags, tagsFilter } = this.state

    return (
      <div className="d-inline-block mr-3 ">
        <SearchOutlined onClick={this.showLiveSearch} style={{ fontSize: 17, color: '#fff' }} />

        <div
          className={`${
            showSearch ? `${style.livesearch} ${style.livesearchVisible}` : style.livesearch
          }`}
          id="livesearch"
        >
          <button className={style.close} type="button" onClick={this.hideLiveSearch}>
            <i className="icmn-cross" />
          </button>
          <div className="container-fluid">
            <div className={style.wrapper}>
              <input
                type="search"
                className={style.searchInput}
                value={searchText}
                onChange={this.changeSearchText}
                onKeyDown={this.handleKeyDown}
                id="livesearchInput"
                placeholder={language.topBar_typeToSearch}
                ref={this.handleNode}
              />
              <h6 className={style.optionEnter}>{language.topBar_search_hint}</h6>
              <ul className={style.options}>
                <li className={style.option}>
                  <h4 className={style.optionFilter}>{language.topBar_search_filter}:</h4>
                  <Tags
                    tags={defaultTags.topFunnel}
                    tagClick={e => this.addTag(e)}
                    selected={tagsFilter}
                  />
                  <Tags
                    tags={defaultTags.middleEndFunnel}
                    tagClick={e => this.addTag(e)}
                    selected={tagsFilter}
                  />
                  <Tags
                    tags={defaultTags.currentSituation}
                    tagClick={e => this.addTag(e)}
                    selected={tagsFilter}
                  />
                  <Tags
                    tags={[
                      defaultTags.whatsapp,
                      defaultTags.linkedin,
                      defaultTags.instagram,
                      defaultTags.google,
                      defaultTags.cne,
                      defaultTags.facebook,
                      defaultTags.redrive,
                      defaultTags.indicacao,
                    ]}
                    tagClick={e => this.addTag(e)}
                    selected={tagsFilter}
                  />
                </li>
                <li className="text-center">
                  <Button type="link" block onClick={this.cleanFilters}>
                    {language.topBar_search_filterClear}
                  </Button>
                </li>
              </ul>

              {!resultSearch ||
                (resultSearch.data.hits.total.value === 0 && (
                  <div className={style.results}>
                    <div className={`text-center ${style.resultsTitle}`}>
                      <span>{language.topBar_search_emptyResult}</span>
                    </div>
                  </div>
                ))}
              {resultSearch && resultSearch.data.hits.total.value > 0 && (
                <div className={style.results}>
                  <div className={style.resultsTitle}>
                    <span>{language.topBar_search_result_title}</span>
                  </div>
                  <div className="row">
                    {resultSearch.data.hits.hits.map(doc => {
                      return (
                        <div className="col-lg-4 d-flex">
                          <div className={style.resultContent}>
                            <Link to={`/profile/${doc._id}`} onClick={this.hideLiveSearch}>
                              <Avatar
                                className={style.resultThumb}
                                size={58}
                                src={`${doc._source.picture ||
                                  '/resources/images/avatars/avatar-4.jpg'}`}
                              />
                            </Link>

                            <div className={style.result}>
                              <Link to={`/profile/${doc._id}`} onClick={this.hideLiveSearch}>
                                <div className={style.resultText}>
                                  {doc._source.firstname} {doc._source.lastname}
                                </div>
                                <div className={style.resultSource}>
                                  <NumberFormat
                                    value={doc._source.phone}
                                    format="+## (##) #########"
                                    displayType="text"
                                  />
                                </div>
                                <Tags tags={doc._source.tags} />
                              </Link>
                            </div>
                          </div>
                          <Dropdown
                            overlay={() => this.renderDropdownMenu(doc)}
                            placement="bottomRight"
                          >
                            <button
                              type="button"
                              className={`btn btn-light dropdown-toggle dropdown-toggle-noarrow ${style.dropdownButton}`}
                            >
                              {/* <i className="fe fe-more-horizontal" /> */}
                            </button>
                          </Dropdown>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Search
