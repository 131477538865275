import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Menu, Popover } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import store from 'store'
import { find } from 'lodash'
import UserMenu from '../../TopBar/UserMenu'
import Actions from '../../TopBar/Actions'
import ACL from '../../../system/ACL'
import Learning from '../../TopBar/Learning'
import Search from '../../TopBar/Search'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  logo: settings.logo,
  menuColor: settings.menuColor,
  role: user.role,
  waitingPayment: user.waitingPayment,
})

const menuSize = () => {
  const screenWidth = window.innerWidth
  const logoNormal = document.getElementById('logo-normal')
  const logoReducida = document.getElementById('logo-reduzida')

  if (logoNormal && logoReducida) {
    if (screenWidth >= 750 && screenWidth <= 1450) {
      logoNormal.style.display = 'none'
      logoReducida.style.display = 'block'
    } else {
      logoNormal.style.display = 'block'
      logoReducida.style.display = 'none'
    }
  }
}

// Call the function on window resize
window.addEventListener('resize', menuSize)

// Call the function on page load
menuSize()

const MenuTop = ({
  menuData = [],
  location: { pathname },
  // logo,
  role,
  waitingPayment,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    /* const {
      intl: { formatMessage },
    } = this.props */

    const generateItem = item => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return null
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled || waitingPayment}>
            {item.target && (
              <Link to={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{item.title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </Link>
            )}
            {!item.target && (
              <Link to={url}>
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{item.title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled || waitingPayment}>
          {icon && <span className={`${icon} ${style.icon}`} />}
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
        </Menu.Item>
      )
    }
    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.roles && !menuItem.roles.includes(role)) {
          return null
        }
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })
    return menuData.map(menuItem => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  return (
    <div className={`row ${style.menu}`}>
      <div className={style.logoContainer}>
        <div className={style.logo}>
          <Link to="/dashboard/">
            <img
              id="logo-normal"
              className={style.logoImg}
              src="/resources/images/redrive-white-logo.png"
              alt="Redrive Força de Vendas"
            />
            <img
              id="logo-reduzida"
              src="/resources/images/redrive-white.png"
              className={style.logoImg}
              style={{ display: 'none' }}
              alt="Redrive Força de Vendas"
            />
          </Link>
          <span>BETA {process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
      <div className={style.navigation}>
        <Menu
          onClick={handleClick}
          selectedKeys={selectedKeys}
          mode="horizontal"
          defaultSelectedKeys={['1']}
        >
          {generateMenuItems()}
        </Menu>
      </div>
      <div className={style.usermenu}>
        <ACL
          roles={[
            'attendant',
            'basic',
            'pro',
            'pro2',
            'enterprise',
            'seller',
            'partner',
            'admin',
            'board',
          ]}
        >
          <div className={style.usermenuIcon}>
            <Search />
          </div>
        </ACL>
        <Popover content="Redrive Play" placement="bottom">
          <div className={style.usermenuIcon}>
            <Learning />
          </div>
        </Popover>
        <div className={style.usermenuIcon}>
          <Actions />
        </div>
        <UserMenu />
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MenuTop))
