import React from 'react'
import style from './style.module.scss'
const language = require('components/ChangeLanguage').getMessage('dashboard')

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a
          href="https://redrive.com.br"
          target="_blank"
          rel="noopener noreferrer"
          className={style.logo}
        >
          redrive.com.br
        </a>
        <br />
        <p className="mb-0">
          <a href="https://redrive.com.br/termos" target="_blank" rel="noopener noreferrer">
            {language.footer_terms}
          </a>
          {' | '}
          <a
            href="https://redrive.com.br/politica-de-privacidade"
            target="_blank"
            rel="noopener noreferrer"
          >
            {language.footer_policy}
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
