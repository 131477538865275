/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
// import { Alert } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import style from './style.module.scss'
// const { TabPane } = Tabs
const language = require('components/ChangeLanguage').getMessage('menuprincipal')

const List2 = props => {
  const { notifications = [] } = props

  const setSeen = notification => {
    if (!notification.data().seen) {
      notification.ref.update({
        seen: true,
        seenAt: new Date(),
      })
    }
  }
  return (
    <div style={{ padding: '10px' }}>
      <p className={style.title}>
        <strong className="text-black">{language.notificationsBar_title}</strong>
      </p>
      <ul className="list-unstyled">
        {notifications
          .sort((a, b) => b.data().createdAt.toDate() - a.data().createdAt.toDate())
          .map(notification => {
            const data = notification.data()
            const message = (
              <Link to={data.link}>
                {data.text}{' '}
                <time className={style.time}>{moment(data.createdAt.toDate()).calendar()}</time>
              </Link>
            )
            return (
              <li
                key={notification.id}
                className={`mb-3 ${style.head} `}
                onClick={() => setSeen(notification)}
                style={{
                  opacity: data.seen ? '0.5' : '1',
                  fontWeight: data.seen ? 'normal' : 'bold',
                }}
              >
                {data.type === 'success' ? (
                  <i className={`fe fe-check-circle ${style.iconTypeSucess}`} />
                ) : (
                  <i className={`fe fe-info ${style.iconType}`} />
                )}
                {message}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default List2
