/* eslint-disable no-unused-vars */
import axios from 'axios'
import { notification } from 'antd'
import { history } from 'index'
import moment from 'moment'
import formurlencoded from 'form-urlencoded'

// TODO ENV THIS
const config = {
  apiAppToken: '98a6d39c-f3c0-47a0-91ac-e8d848dc84be',
  apiAccessToken: '9f02b79b-cf5b-4584-b800-cee97c357969',
  endPoint: 'https://api.superlogica.net/v2',
}

const transformRequest = (params, headers) => {
  if (params) {
    params = formurlencoded(params)
  }
  // console.log('params', params, headers)
  return params
}

const superLogicaApi = axios.create({
  baseURL: config.endPoint,
  headers: {
    app_token: config.apiAppToken,
    access_token: config.apiAccessToken,
  },
  transformRequest,
})

const enumUserRole = {
  '1': 'basic',
  '6': 'basic',
  '8': 'basic',
  '14': 'basic',
  '16': 'basic',
  '2': 'pro',
  '4': 'pro',
  '9': 'pro',
  '11': 'pro',
  '12': 'pro',
  '13': 'pro',
  '17': 'pro',
  '29': 'pro2',
  '30': 'pro2',
  '3': 'enterprise',
  '5': 'enterprise',
  '10': 'enterprise',
  '18': 'enterprise',
  '31': 'enterprise',
  '32': 'enterprise',
  '1000': 'seller',
  '1003': 'partner',
  '0': 'attendant',
}

async function getSuperLogicaSMS() {
  const superLogicaSMS = await superLogicaApi
    .get('/financeiro/cobranca', {
      params: {
        PRODUTOS: 10,
      },
    })
    .catch(error => {
      console.error(error)
    })

  const valorCred = []
  let totalCred = 0

  const totalRecb = []
  let recb = 0

  if (superLogicaSMS) {
    const { data } = superLogicaSMS
    // console.log('dados superlogica', data)
    if (data && data.length > 0) {
      for (const dt in data) {
        valorCred.push(Number(data[dt].vl_valorcreditado_calc))
        totalRecb.push(Number(data[dt].vl_total_recb))
      }
    }
    valorCred.forEach(v1 => {
      totalCred += v1
    })

    totalRecb.forEach(v2 => {
      recb += v2
    })
  }

  return { totalCred, recb }
}

async function getSuperLogicaUser(email) {
  if (email) {
    const superLogicaUser = await superLogicaApi
      .get('/financeiro/clientes', {
        params: {
          pagina: 1,
          itensPorPagina: 1,
          pesquisaEmTudo: email,
        },
      })
      .catch(error => {
        console.error(error)
      })
    if (superLogicaUser) {
      const { data } = superLogicaUser
      // console.log('dados superlogica', data)
      if (data && data.length > 0) {
        const [user] = data
        return user
      }
    }

    return {}
  }

  return {}
}

async function getSuperLogicaUserPayments(email, alert = false) {
  if (email) {
    const user = await getSuperLogicaUser(email)
    if (user && user.id_sacado_sac && user.dt_desativacao_sac) {
      const { id_sacado_sac: superlogicaUserId, dt_desativacao_sac: disableDate } = user
      const userActive = !disableDate.length
      if (userActive && superlogicaUserId) {
        const subscription = await superLogicaApi
          .get('/financeiro/recorrencias/recorrenciasdeplanos', {
            params: {
              pagina: 1,
              itensPorPagina: 50,
              tipo: 'contratos',
              gridMensalidadesAgrupadasPorPlano: true,
              semTrial: true,
              'CLIENTES[0]': superlogicaUserId,
            },
          })
          .catch(error => {
            console.log(error)
          })
        if (subscription && subscription.data && subscription.data.length === 0) {
          notification.warning({
            message: 'Assinatura Inexistente',
            description:
              'Não conseguimos encontrar uma assinatura ativa nesse login, por favor entre em contato com o nosso suporte!',
          })
          return { payments: [], user: {}, waitingPayment: true }
        }
        const userPaymentsResponse = await superLogicaApi
          .get('/financeiro/cobranca', {
            params: {
              pagina: 1,
              itensPorPagina: 50,
              doClienteComId: superlogicaUserId,
              semFiltroPorData: 1,
            },
          })
          .catch(error => {
            console.error(error)
          })
        if (alert && userPaymentsResponse.data && userPaymentsResponse.data.length) {
          let isPastDueDate
          const needToAlertWaitingPayment = userPaymentsResponse.data.find(p => {
            const {
              dt_liquidacao_recb: settlementDate,
              dt_recebimento_recb: receiptDate,
              dt_vencimento_recb: dueDate,
            } = p

            // caso seja boleto valida a trava do pagamento pra 1 dia a mais.
            if (p.ar_nomeformas_calc === 'Boleto') {
              const dayPlusOne = moment(dueDate)
                .add(1, 'days')
                .format('MM/DD/YYYY')
              isPastDueDate = moment(dayPlusOne, 'MM/DD/YYYY').isBefore(moment())
            } else {
              isPastDueDate = moment(dueDate, 'MM/DD/YYYY').isBefore(moment())
            }

            // console.log('isPastDueDate', isPastDueDate)
            return isPastDueDate && !settlementDate && !receiptDate
          })

          if (needToAlertWaitingPayment) {
            notification.warning({
              message: 'Aguardando Pagamento',
              description:
                'Você possui um pagamento que ainda não foi processado, acesse seus pagamentos, em configuracões, para verificar!',
              onClick: () => history.push('/settings?activeKey=4'),
            })
          }
          return {
            payments: userPaymentsResponse.data,
            user,
            waitingPayment: !!needToAlertWaitingPayment,
          }
        }
        return { payments: userPaymentsResponse.data, user, waitingPayment: false }
      }
      return { payments: [], user: {}, waitingPayment: false }
    }
  }
  return { payments: [], user: {}, waitingPayment: false }
}

async function getSuperLogicaUserRole(email) {
  const user = await getSuperLogicaUser(email)

  const { id_sacado_sac: id } = user
  // console.log('id:', id)
  if (email) {
    const userFornecedoresResponse = await superLogicaApi
      .get('/financeiro/fornecedores', {
        params: {
          pagina: 1,
          itensPorPagina: 1,
          pesquisa: `email:${email}`,
        },
      })
      .catch(error => {
        console.error(error)
      })

    if (userFornecedoresResponse) {
      const { data } = userFornecedoresResponse
      // console.log('user fornecedor:', data)

      const [userResponse] = data
      if (userResponse) {
        const { favorecidos_tags: userTags } = userResponse
        if (userTags && userTags.length > 0) {
          const [tag] = userTags
          return enumUserRole[tag.id_tag_ftag]
        }
      }
    }

    if (id) {
      const userPlansResponse = await superLogicaApi
        .get('/financeiro/recorrencias/recorrenciasdeplanos', {
          params: {
            pagina: 1,
            itensPorPagina: 1,
            'CLIENTES[0]': id,
          },
        })
        .catch(error => {
          console.error(error)
        })

      if (userPlansResponse) {
        const { data } = userPlansResponse
        //  console.log('data',data)
        const [userPlan] = data
        if (userPlan) {
          return enumUserRole[userPlan.id_plano_pla]
        }
        return false
      }
    }

    return false
  }
  return false
}

export {
  superLogicaApi,
  getSuperLogicaUserPayments,
  getSuperLogicaUser,
  getSuperLogicaUserRole,
  getSuperLogicaSMS,
}
