import React from 'react'
import indexScss from './index.module.scss'
import { notification } from 'antd'

//import ptBr from '../../assets/images/flags/brazil.svg'
//import usa from '../../assets/images/flags/usa.svg'
//import spain from '../../assets/images/flags/spain.jpg'

let showNotification = false
const languageKey = 'app.settings.language'

const usaFlagEmoji = '\u{1F1FA}\u{1F1F8}'
const brazilFlagEmoji = '\u{1F1E7}\u{1F1F7}'
const spainFlagEmoji = '\u{1F1EA}\u{1F1F8}'
const italyFlagEmoji = '\u{1F1EE}\u{1F1F9}'

const languages = [
  { code: 'pt-br', name: 'Português', flag: brazilFlagEmoji },
  { code: 'en-us', name: 'Inglês', flag: usaFlagEmoji },
  { code: 'es-es', name: 'Espanhol', flag: spainFlagEmoji },
  { code: 'it-it', name: 'Italiano', flag: italyFlagEmoji },
]

const languageObj = {
  'pt-br': 'Português Brasileiro',
  'en-us': 'English',
  'es-es': 'Espanhol',
  'it-it': 'Italiano',
}

export function getLocale() {
  const locale = localStorage.getItem(languageKey) || 'pt-br'

  return locale
}

export function getMessage(page) {
  let message = {}
  page = page.replace(/\//g, '_')
  const language = localStorage.getItem(languageKey) || 'pt-br'

  try {
    message = require(`../../locales/${language}/${page}.json`)
  } catch (error) {
    if (!showNotification) {
      showNotification = true
      setTimeout(() => {
        notification.warning({
          message: `Não foi possível carregar o idioma "${languageObj[language]}", voltando para "${languageObj['pt-br']}".`,
          style: {
            width: '800px',
            marginTop: '45px',
            marginRight: '-15px',
          },
        })
      }, 1000)
    }
    const selectElement = document.getElementById('selectLanguage')
    if (selectElement) {
      selectElement.value = 'pt-br'
      localStorage.setItem(languageKey, selectElement.value)
    }
    message = require(`../../locales/pt-br/${page}.json`)
  }

  message.__ = (str = '', ...params) => {
    if (typeof str !== 'string') return ''

    if (!Array.isArray(params)) params = []

    let value = message[str] || str

    params.forEach((item, index) => {
      value = value.replace(`{{${index}}}`, item)
    })

    return value
  }

  return message
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)

    let currentLanguage = localStorage.getItem(languageKey)
    let userLanguage = navigator.language || navigator.userLanguage
    if (!currentLanguage) {
      //currentLanguage = 'en-us'
      switch (userLanguage.split('-')[0].toLowerCase()) {
        case 'en':
          currentLanguage = 'en-us'
          break
        case 'es':
          currentLanguage = 'es-es'
          break
        default:
          currentLanguage = 'pt-br'
          break
        case 'it':
          currentLanguage = 'it-it'
          break
      }
      localStorage.setItem(languageKey, currentLanguage)
    }

    this.state = { currentLanguage }

    this.changeLanguage = this.changeLanguage.bind(this)
  }

  async changeLanguage(event) {
    let currentLanguage = event.target.value || this.state.currentLanguage

    this.setState({ currentLanguage })

    localStorage.setItem(languageKey, currentLanguage)

    window.location.reload()
  }

  render() {
    const { currentLanguage } = this.state

    return (
      <>
        <div id={`${indexScss.divLanguage}`}>
          <select id="selectLanguage" onChange={this.changeLanguage} defaultValue={currentLanguage}>
            {languages.map(item => (
              <option key={item.code} value={item.code} title={item.name}>
                {item.flag}
              </option>
            ))}
          </select>
          {/* <img
            src={languages.find(lang => lang.code === currentLanguage)?.flagImage}
            style={{ width: '15%', marginLeft: '5%' }}
          /> */}
        </div>
      </>
    )
  }
}
