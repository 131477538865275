import axios from 'axios'

class Elasticsearch {
  // eslint-disable-next-line class-methods-use-this
  async call(query = null, limit = 100, offset = 0, index = 'redrive1', count = false) {
    const esEndpoint = 'https://es4.redrive.com.br'

    const results = []

    let uri = esEndpoint
    if (count) {
      uri += `/${index}/_count`
    } else {
      uri += `/${index}/_search?size=${limit}&from=${offset}`
    }

    const res = axios.post(uri, query, {
      headers: {
        Authorization: 'Basic cmVkcml2ZTppKmhabjM2Qk4zOWk=',
        // r7FnYWo$bp$N7@rU
      },
    })
    results.push(res)

    // console.log('res', res)

    // caso seja uma pesquisa comum, colocamos o numero real de items
    // o ES limita a contagem a 10k caso nao seja pelo metodo _count
    // entao unimos o _search com o _count aqui
    if (!count) {
      delete query.sort
      const uriCount = `${esEndpoint}/${index}/_count`
      const resCount = axios.post(uriCount, query, {
        headers: {
          Authorization: 'Basic cmVkcml2ZTppKmhabjM2Qk4zOWk=',
        },
      })
      results.push(resCount)
      // res.data.hits.total.value = resCount.data.count
    }

    const resolved = await Promise.all(results)

    if (resolved && resolved[1]) {
      resolved[0].data.hits.total.value = resolved[1].data.count
    }

    return resolved[0]
  }
}

export default (query, limit, offset, index, count) =>
  new Elasticsearch().call(query, limit, offset, index, count)
