import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Form, message } from 'antd'
import { Link } from 'react-router-dom'
import style from '../style.module.scss'
import { updateUserPassword, login, currentAccount } from 'services/firebase'
import { history } from 'index'

const language = require('components/ChangeLanguage').getMessage('login')

const mapStateToProps = ({ user, settings, dispatch }) => ({
  dispatch,
  user,
  authProvider: settings.authProvider,
  logo: settings.logo,
})

class Login extends Component {
  constructor(props) {
    super()
    this.state = {
      loading: false,
    }
  }
  async sleep(ms) {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  onFinish = async values => {
    const user = await currentAccount()
    this.setState({ loading: true })
    updateUserPassword(values.password)
      .then(async res => {
        message.success({
          content: language.updatePassword_message_success,
          style: {
            marginTop: '50px',
          },
        })
        history.push('/')
      })
      .catch(() => {
        message.success({
          content: language.updatePassword_message_error,
          style: {
            marginTop: '50px',
          },
        })
        history.push('/auth/login')
      })
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  render() {
    const { authProvider, user } = this.props
    const { loading } = this.state

    return (
      <div>
        <div className="text-center mb-5" />
        <div className={`card ${style.container}`}>
          <div className="text-dark font-size-24 mb-1">
            <strong>{language.updatePassword_form_title}</strong>
          </div>

          <Form
            value={authProvider}
            layout="vertical"
            hideRequiredMark
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="mb-4"
            initialValues={{ password: '', passwordConfirm: '' }}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: language.updatePassword_form_input_password_message_validate,
                },
              ]}
            >
              <Input
                size="large"
                type="password"
                placeholder={language.updatePassword_form_input_password_placeholder}
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message:
                    language.updatePassword_form_input_confirmpassword_message_validade_required,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        language.updatePassword_form_input_confirmpassword_message_validade_math,
                      ),
                    )
                  },
                }),
              ]}
            >
              <Input
                size="large"
                type="password"
                placeholder={language.updatePassword_form_input_confirmpassword_placeholder}
              />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              className="text-center w-100"
              htmlType="submit"
              loading={user.loading}
            >
              <strong>{language.updatePassword_form_button_submit}</strong>
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Login)
