// @flow
import {
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  OPEN_MAESTRO_SIDEBAR,
  CLOSE_MAESTRO_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
} from './constants'

// import actions from './actions'

const INIT_STATE = {
  activeTab: '1',
  userSidebar: false,
  maestroSidebar: false,
  conversationName: 'Doris Brown',
}

const Layout = (state = INIT_STATE, action) => {
  // console.log('action.type',action.type)
  switch (action.type) {
    case SET_ACTIVE_TAB:
      // console.log('action.payload', action.payload)
      return {
        ...state,
        activeTab: action.payload,
      }

    case OPEN_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: true,
      }

    case CLOSE_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: false,
      }

    case OPEN_MAESTRO_SIDEBAR:
      return {
        ...state,
        maestroSidebar: true,
      }

    case CLOSE_MAESTRO_SIDEBAR:
      return {
        ...state,
        maestroSidebar: false,
      }

    case SET_CONVERSATION_NAME_IN_OPEN_CHAT:
      return {
        ...state,
        conversationName: action.payload,
      }
    default:
      return state
  }
}

export default Layout
