import React from 'react'
import { Space } from 'antd'
import { Spinner } from 'reactstrap'

const language = require('../../components/ChangeLanguage').getMessage('settings/whatsapp')

class Loading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: language.component_loading_message,
    }
  }

  async componentDidMount() {
    // const { subMessage } = this.state
    // setTimeout(() => {
    //   subMessage = `Dica: Mantenha seu WhatsApp aberto para uma conexão mais rápida!`
    //   this.setState({ subMessage })
    // }, 5000)
  }

  render() {
    const { message, subMessage } = this.state

    return (
      <div className="d-flex justify-content-center">
        <Space direction="vertical" align="center">
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          <br />
          <p className="text-center">{message}</p>
          {this.props.subMessage ? <p className="text-center">{this.props.subMessage}</p> : ''}
        </Space>
      </div>
    )
  }
}

export default Loading
