import {
  AimOutlined,
  DollarOutlined,
  MailOutlined,
  NotificationOutlined,
  PartitionOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { message as popup } from 'antd'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { history, queryClient } from 'index'
import { currentAccount, db } from 'services/firebase'

const language = require('components/ChangeLanguage').getMessage('automate')

const ChatFlowContext = createContext({})

const defaultCategories = [
  { label: language.chatFlowHook_defaultCategories_communication, icon: <MailOutlined /> },
  { label: language.chatFlowHook_defaultCategories_money, icon: <DollarOutlined /> },
  { label: language.chatFlowHook_defaultCategories_search, icon: <QuestionCircleOutlined /> },
  { label: language.chatFlowHook_defaultCategories_prospection, icon: <AimOutlined /> },
  { label: language.chatFlowHook_defaultCategories_attendant, icon: <PartitionOutlined /> },
  { label: language.chatFlowHook_defaultCategories_marketing, icon: <NotificationOutlined /> },
]

const ChatFlowProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(undefined)
  const [loading, setLoading] = useState(false)

  // Duplicate
  const [extraDuplicateInformation, setExtraDuplicateInformation] = useState({
    edges: '',
    entities: '',
    nodes: '',
  })
  //

  // List templates
  const [listTemplatesModalIsOpen, setListTemplatesModalIsOpen] = useState(false)
  //

  // New Flow
  const [newFlowModalIsOpen, setNewFlowModalIsOpen] = useState(false)
  const [newFlow, setNewFlow] = useState({
    newFlowName: '',
    newFlowDescription: '',
  })
  //

  // Template
  const [templateModalIsOpen, setTemplateModalIsOpen] = useState(false)
  const [template, setTemplate] = useState({
    templateName: '',
    templateDescription: '',
    category: '',
    imageFile: '',
  })
  const [templateId, setTemplateId] = useState(undefined)
  //

  const handleDuplicateFlow = useCallback(
    async (flowId, duplicateTemplate) => {
      try {
        if (!flowId) return

        setLoading(true)

        const existingTemplate = (
          await db
            .collection('dialogflow')
            .doc(flowId)
            .get()
        ).data()

        if (!duplicateTemplate) {
          const emptyTemplate = {
            templateName: '',
            templateDescription: '',
            category: '',
            imageFile: '',
          }

          setTemplate(emptyTemplate)
        }

        const {
          edges,
          entities,
          nodes,
          description,
          botname,
          template: savedTemplate,
        } = existingTemplate

        const data = { edges, entities, nodes, description, botname }

        if (duplicateTemplate) {
          const newTemplate = {
            ...savedTemplate,
            templateName: `${savedTemplate.templateName} (copy)`,
            templateDescription: `${savedTemplate.templateDescription} (copy)`,
          }

          setTemplate(newTemplate)
        }

        // Pre-populating the data
        setExtraDuplicateInformation(data)
        setNewFlow({
          newFlowName: `${botname} (copy)`,
          newFlowDescription: `${description} (copy)`,
        })

        // Opening the modal to inform data of the new flow
        setListTemplatesModalIsOpen(false)
        setNewFlowModalIsOpen(true)
      } catch (error) {
        popup.error(language.chatFlowHook_message_error_duplicate)
      } finally {
        setLoading(false)
      }
    },
    [newFlow],
  )

  const handleCreateFlow = useCallback(
    async event => {
      event?.preventDefault()

      try {
        const { newFlowName, newFlowDescription } = newFlow
        const { templateName, templateDescription } = template

        // When creating a template, the name and description are reused
        const botname = newFlowName || templateName
        const description = newFlowDescription || templateDescription

        if (!botname || !description) {
          popup.error(language.chatFlowHook_message_error_validateFields)
          return
        }

        setLoading(true)

        const { owner, id } = await currentAccount()
        const user = owner || id

        const docRef = db.collection('dialogflow').doc()

        let newFlowId

        const data = {
          ...extraDuplicateInformation,
          botname,
          description,
          type: 'dialogflow',
          user,
        }

        if (!!template && !!template.templateName) {
          Object.assign(data, {
            template: {
              ...template,
              templateName: botname,
              templateDescription: description,
            },
          })
        }

        await docRef
          .set(data, { merge: true })
          .then(() => {
            newFlowId = docRef.id
            popup.success('Fluxo criado com sucesso')
            queryClient.invalidateQueries(['flowList', id])
            queryClient.invalidateQueries(['flowList', owner])
            return newFlowId
          })
          .catch(err => popup.error(language.chatFlowHook_message_error_newFlow_1, err))

        history.push(`/chatflow/flow/${newFlowId}`)
      } catch (error) {
        console.log(error)
        popup.error(language.chatFlowHook_message_error_newFlow_2)
      } finally {
        setLoading(false)
      }
    },
    [newFlow, template, extraDuplicateInformation],
  )

  const handleCreateTemplate = useCallback(
    async event => {
      event.preventDefault()

      const { templateName, templateDescription, category, imageFile } = template

      const insufficientInformation =
        !templateName || !templateDescription || !category || !imageFile

      // console.log(template)
      if (insufficientInformation) {
        popup.error(language.chatFlowHook_message_error_validateFields)
        return
      }

      // Opening the modal to inform data of the new flow
      setTemplateModalIsOpen(false)
      handleCreateFlow()
    },
    [template],
  )

  const handleUpdateTemplate = useCallback(
    async event => {
      event.preventDefault()

      try {
        const { category, imageFile, templateDescription, templateName } = template
        if (!templateId || !category || !imageFile || !templateDescription || !templateName) {
          popup.error(language.chatFlowHook_message_error_updateTemplate_1)
          return
        }

        setLoading(true)

        const data = {
          botname: templateName,
          template,
        }

        // Update the document
        await db
          .collection('dialogflow')
          .doc(templateId)
          .set(data, { merge: true })

        popup.success(language.chatFlowHook_message_success_updateTemplate)
      } catch {
        popup.error(language.chatFlowHook_message_error_updateTemplate_2)
      } finally {
        setLoading(false)
      }
    },
    [template, templateId],
  )

  const handleUpdateFlow = useCallback(async (event, flowData) => {
    event.preventDefault()

    try {
      const { name, description, docId } = flowData

      if (!docId || !description || !name) {
        popup.error(language.chatFlowHook_message_error_updateFlow_1)
        return
      }

      setLoading(true)

      const data = {
        botname: name,
        description,
      }

      // Update the document
      await db
        .collection('dialogflow')
        .doc(docId)
        .set(data, { merge: true })

      popup.success(language.chatFlowHook_message_sucess_updateFlow)
    } catch {
      popup.error(language.chatFlowHook_message_error_updateFlow_2)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteTemplate = useCallback(async template_id => {
    const documentRef = db.collection('dialogflow').doc(template_id)
    const { id } = await currentAccount()

    // const userID = owner || id

    try {
      setLoading(true)
      await documentRef.update({ deleted: true, deletedBy: id, deletedAt: new Date() })
      setListTemplatesModalIsOpen(false)
      queryClient.invalidateQueries(['flowList', id])
    } catch (error) {
      popup.error(language.chatFlowHook_message_error_deleteTemplate)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const getUserRole = async () => {
      try {
        setLoading(true)

        const account = await currentAccount()
        const role = account?.role

        if (role !== undefined) {
          setUserRole(role)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setLoading(false)
      }
    }

    getUserRole()
  }, [])

  return (
    <ChatFlowContext.Provider
      value={{
        userRole,
        loading,
        setLoading,
        defaultCategories,

        // List templates
        listTemplatesModalIsOpen,
        setListTemplatesModalIsOpen,

        // New Flow
        newFlowModalIsOpen,
        setNewFlowModalIsOpen,
        newFlow,
        setNewFlow,
        handleCreateFlow,
        handleDuplicateFlow,
        handleUpdateFlow,
        //

        // Template
        templateModalIsOpen,
        setTemplateModalIsOpen,
        template,
        setTemplate,
        templateId,
        setTemplateId,
        handleCreateTemplate,
        handleUpdateTemplate,
        handleDeleteTemplate,
        //
      }}
    >
      {children}
    </ChatFlowContext.Provider>
  )
}

const useChatFlow = () => {
  const context = useContext(ChatFlowContext)
  if (!context) throw new Error(language.chatFlowHook_throw_error)
  return context
}

export { ChatFlowContext, ChatFlowProvider, useChatFlow }
