import { db } from '../../services/firebase'
import { notification } from 'antd'
import { history } from 'index'
import axios from 'axios'

const language = require('components/ChangeLanguage').getMessage('services/firebase')

const roleLatePayment = 'late-payment'
const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyMzI3MzM0OTQ0MjIyMCIsIm5hbWUiOiJBZHJpYW5vTW91cmEiLCJpYXQiOjIzMjczNX0.gr_AMYMFireTNFF643O2C-dO5CmW2eWijjR_ZllRVXg'

const sleep = async ms => {
  // eslint-disable-next-line no-undef
  return new Promise(resolve => setTimeout(resolve, ms))
}

async function redirectUser(message, path) {
  notification.error({ message })
  await sleep(3000)
  history.push(path)
  await sleep(1000)
  window.location.reload()
}

async function changeRole(userId, newRole) {
  const docUser = db.collection('users').doc(userId)
  const docData = (await docUser.get()).data()
  const newRoles = {}
  newRoles.oldRole = docData.role

  if (newRole !== undefined) {
    newRoles.role = newRole
  } else {
    newRoles.role = docData.oldRole
  }
  if (newRoles.oldRole === 'late-payment') {
    delete newRoles.oldRole
  }

  await docUser.set(newRoles, { merge: true })
}

export async function checkUser(email) {
  const config = { headers: { authorization: token, 'Content-Type': 'application/json' } }
  const data = { email }

  console.log(`checando inadimplência de ${email} ...`)

  await axios
    .post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_ENDPOINT}/checkDefaulterUser`, data, config)
    .catch(error => {
      notification.error({ message: `${language.checkUser_notification_error} ${email} !` })
    })
    .then(async res => {
      const data = res?.data
      if (data?.code === 200 && data?.role === roleLatePayment) {
        await changeRole(data?.userId)
        await redirectUser(language.checkUser_redirectUser_success, '/dashboard')
      }

      if (data?.code > 400) {
        await changeRole(data?.userId, roleLatePayment)
        await redirectUser(language.checkUser_redirectUser_warning, '/settings?activeKey=2')
      }
    })
}
