import React from 'react'
import { Link } from 'react-router-dom'
// import { currentAccount, getNotifications } from 'services/firebase'
import styles from './style.module.scss'

const Learning = () => {
  return (
    <Link to="/play">
      <div className={styles.dropdown}>
        <i className={`${styles.icon} fe fe-youtube`} />
      </div>
    </Link>
  )
}

export default Learning
