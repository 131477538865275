import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Loading from 'components/loading'
import ACL from 'components/cleanui/system/ACL'
import ConnectionCheck from 'components/kit/widgets/General/wapadd/whatsapp-connection-check'

import Layout from 'layouts'
import Hotjar from 'hotjar'

const routes = [
  // Dashboards
  // {
  //   path: '/dashboard/alpha',
  //   Component: lazy(() => import('pages/dashboard/alpha')),
  //   exact: true,
  // },
  {
    path: '/copy-generator',
    Component: lazy(() => import('pages/copy-generator')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/heat-number',
    Component: lazy(() => import('pages/heat-number')),
    roles: ['basic', 'pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support'],
    exact: true,
  },
  {
    path: '/crm',
    Component: lazy(() => import('pages/crm')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/chatflow/flow/:id',
    Component: lazy(() => import('pages/chatflow')),
    roles: ['pro2', 'enterprise', 'board', 'seller', 'support', 'facebook', 'partner'],
    exact: true,
  },
  {
    path: '/chatflow',
    Component: lazy(() => import('pages/chatflowlist')),
    roles: ['pro2', 'enterprise', 'board', 'seller', 'support', 'facebook', 'partner'],
    exact: true,
  },
  {
    path: '/exports',
    Component: lazy(() => import('pages/exports')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/imports',
    Component: lazy(() => import('pages/imports')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/crm/cadastro',
    Component: lazy(() => import('pages/crm/cadastro')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: '/play',
    Component: lazy(() => import('pages/play')),
    exact: true,
  },
  {
    path: '/play/video/:id',
    Component: lazy(() => import('pages/play/video')),
    exact: true,
  },
  {
    path: '/play/courses',
    Component: lazy(() => import('pages/play/courses')),
    exact: true,
  },
  {
    path: '/play/courses/:slug',
    Component: lazy(() => import('pages/play/courses/lessons')),
    exact: true,
  },
  {
    path: '/play/courses/:slug/:lessonId',
    Component: lazy(() => import('pages/play/courses/lesson')),
    exact: true,
  },
  // Leads
  {
    path: '/leads/whatsapp',
    Component: lazy(() => import('pages/leads/whatsapp')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/leads/whatsapp/contacts',
    Component: lazy(() => import('pages/leads/whatsapp/contacts')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/leads/whatsapp/chats-import',
    Component: lazy(() => import('pages/leads/whatsapp/chats-import')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/whatsapp/chatbot',
    Component: lazy(() => import('pages/leads/whatsapp/chatbot')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/whatsapp/historico',
    Component: lazy(() => import('pages/leads/whatsapp/historico')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/whatsapp/historico/:id',
    Component: lazy(() => import('pages/leads/whatsapp/historico/item')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/linkedin/connectbot',
    Component: lazy(() => import('pages/leads/linkedin/connectbot')),
    roles: ['linkedin', 'pro', 'enterprise', 'board', 'seller', 'partner', 'support'],
    exact: true,
  },
  {
    path: '/linkedin/historico',
    Component: lazy(() => import('pages/leads/linkedin/historico')),
    roles: ['linkedin', 'pro', 'enterprise', 'board', 'seller', 'partner', 'support'],
    exact: true,
  },
  {
    path: '/linkedin/historico/:id',
    Component: lazy(() => import('pages/leads/linkedin/historico/item')),
    roles: ['linkedin', 'pro', 'enterprise', 'board', 'seller', 'partner', 'support'],
    exact: true,
  },
  {
    path: '/whatsapp-extension/chatbot',
    Component: lazy(() => import('pages/leads/whatsapp-extension/connectbot')),
    roles: [
      'whatsapp-extension',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-extension/historico',
    Component: lazy(() => import('pages/leads/whatsapp-extension/historico')),
    roles: [
      'whatsapp-extension',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-extension/historico/:id',
    Component: lazy(() => import('pages/leads/whatsapp-extension/historico/item')),
    roles: [
      'whatsapp-extension',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-business/chatbot',
    Component: lazy(() => import('pages/leads/whatsapp-business/chatbot')),
    roles: [
      'whatsapp-business',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-business/historico',
    Component: lazy(() => import('pages/leads/whatsapp-business/historico')),
    roles: [
      'whatsapp-business',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-business/historico/:id',
    Component: lazy(() => import('pages/leads/whatsapp-business/historico/item')),
    roles: [
      'whatsapp-business',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-business/template/:account_id',
    Component: lazy(() => import('pages/leads/whatsapp-business/template')),
    roles: [
      'whatsapp-business',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-business/template/:account_id/update/:template_id',
    Component: lazy(() => import('pages/leads/whatsapp-business/template/update')),
    roles: [
      'whatsapp-business',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/whatsapp-business/template/:account_id/create',
    Component: lazy(() => import('pages/leads/whatsapp-business/template/create')),
    roles: [
      'whatsapp-business',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
    ],
    exact: true,
  },
  {
    path: '/sms/historico',
    Component: lazy(() => import('pages/leads/sms/historico')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    // roles: ['board'],
    exact: true,
  },
  {
    path: '/sms/historico/:id',
    Component: lazy(() => import('pages/leads/sms/historico/item')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/whatsapp/relatorio',
    Component: lazy(() => import('pages/leads/whatsapp/relatorio')),
    roles: ['basic', 'pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support'],
    exact: true,
  },
  {
    path: '/leads/instagram',
    Component: lazy(() => import('pages/leads/instagram')),
    roles: [
      'admin',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/leads/instagram/post',
    Component: lazy(() => import('pages/leads/instagram/post')),
    roles: [
      'admin',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/leads/instagram/listas',
    Component: lazy(() => import('pages/leads/instagram/scrapers-by-user')),
    roles: [
      'admin',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/instagram/historico',
    Component: lazy(() => import('pages/leads/instagram/historico')),
    exact: true,
  },
  {
    path: '/instagram/historico/:id',
    Component: lazy(() => import('pages/leads/instagram/historico/item')),
    exact: true,
  },
  {
    path: '/leads/linkedin',
    Component: lazy(() => import('pages/leads/linkedin')),
    exact: true,
  },
  {
    path: '/leads/linkedin/listas',
    Component: lazy(() => import('pages/leads/linkedin/scrapers-by-user')),
    roles: ['board', 'seller'],
    exact: true,
  },
  {
    path: '/leads/cadastro-nacional',
    Component: lazy(() => import('pages/leads/cadastro-nacional')),
    roles: [
      'admin',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/leads/csv',
    Component: lazy(() => import('pages/leads/csv')),
    roles: [
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
      'attendant',
    ],
    exact: true,
  },
  {
    path: '/leads/google',
    Component: lazy(() => import('pages/leads/google')),
    roles: ['pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support', 'facebook'],
    exact: true,
  },
  {
    path: '/leads/google/config',
    Component: lazy(() => import('pages/leads/google/config')),
    roles: ['support'],
    exact: true,
  },
  {
    path: '/leads/bing',
    Component: lazy(() => import('pages/leads/bing')),
    roles: ['pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support', 'facebook'],
    exact: true,
  },
  {
    path: '/calendar',
    Component: lazy(() => import('pages/calendar')),
    exact: true,
  },
  // Relatorios
  {
    path: '/report',
    Component: lazy(() => import('pages/report')),
    roles: ['pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support', 'facebook'],
    exact: true,
  },
  {
    path: '/report/whatsapp-totals',
    Component: lazy(() => import('pages/report/whatsapp-totals')),
    roles: ['pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support', 'facebook'],
    exact: true,
  },
  {
    path: '/report/attendance',
    Component: lazy(() => import('pages/report/attendance')),
    roles: ['pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support', 'facebook'],
    exact: true,
  },
  {
    path: '/report/dbm',
    Component: lazy(() => import('pages/report/dbm/index')),
    roles: ['pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support', 'facebook'],
    exact: true,
  },
  {
    path: '/report/performance',
    Component: lazy(() => import('pages/report/performance/index')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/scrapers',
    Component: lazy(() => import('pages/adm/scrapers')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/sending',
    Component: lazy(() => import('pages/adm/whatsapp-sending')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/sending-instagram',
    Component: lazy(() => import('pages/instagram-sending')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/received',
    Component: lazy(() => import('pages/salespeople/received')),
    roles: ['seller', 'partner'],
    exact: true,
  },
  {
    path: '/sellers',
    Component: lazy(() => import('pages/adm/sellers')),
    roles: ['board'],
    exact: true,
  },
  {
    path: '/sales',
    Component: lazy(() => import('pages/adm/sales')),
    roles: ['board'],
    exact: true,
  },
  {
    path: '/recurrence',
    Component: lazy(() => import('pages/adm/recurrence')),
    roles: ['board'],
    exact: true,
  },
  {
    path: '/support/checklist',
    Component: lazy(() => import('pages/adm/support/checklist')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/delete-leads',
    Component: lazy(() => import('pages/adm/support/delete-leads')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/bot-creator',
    Component: lazy(() => import('pages/adm/bots')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/leads-to-account',
    Component: lazy(() => import('pages/adm/support/leads-to-account')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/fix-scraper-numbers',
    Component: lazy(() => import('pages/adm/support/fix-scraper-numbers')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/clear-redrive-scraper',
    Component: lazy(() => import('pages/adm/support/clear-redrive-scraper')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/remove-duplicates',
    Component: lazy(() => import('pages/adm/support/remove-duplicates')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/change-owner',
    Component: lazy(() => import('pages/adm/support/change-owner')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/support/error-systems',
    Component: lazy(() => import('pages/adm/support/error-systems')),
    roles: ['board', 'support'],
    exact: true,
  },
  {
    path: '/salespeople',
    Component: lazy(() => import('pages/salespeople')),
    roles: ['seller', 'partner'],
    exact: true,
  },
  {
    path: '/pending',
    Component: lazy(() => import('pages/salespeople/pending')),
    roles: ['seller', 'partner'],
    exact: true,
  },
  {
    path: '/canceled',
    Component: lazy(() => import('pages/salespeople/canceled')),
    roles: ['seller', 'partner'],
    exact: true,
  },
  {
    path: '/telemetry',
    Component: lazy(() => import('pages/adm/telemetry')),
    roles: ['board'],
    exact: true,
  },
  {
    path: '/manage-plans',
    Component: lazy(() => import('pages/adm/manage-plans')),
    roles: ['board'],
    exact: true,
  },
  // Apps
  /* {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  }, */
  {
    path: '/learning',
    Component: lazy(() => import('pages/learning')),
    exact: true,
  },
  {
    path: '/profile/:id',
    Component: lazy(() => import('pages/crm/profile')),
    roles: [
      'attendantbasic',
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/settings',
    Component: lazy(() => import('pages/settings')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
      'late-payment',
      'attendant',
      'attendantbasic',
    ],
    exact: true,
  },
  {
    path: '/company',
    Component: lazy(() => import('pages/settings/company')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/callback',
    Component: lazy(() => import('pages/callback')),
    exact: true,
  },
  {
    path: '/callback/rd-station',
    Component: lazy(() => import('pages/callback/rd-station')),
    exact: true,
  },
  {
    path: '/callback/pipedrive',
    Component: lazy(() => import('pages/callback/pipedrive')),
    exact: true,
  },
  {
    path: '/callback/hubspot',
    Component: lazy(() => import('pages/callback/hubspot')),
    exact: true,
  },
  {
    path: '/callback/google',
    Component: lazy(() => import('pages/callback/google')),
    exact: true,
  },
  // chat e mensagens automatizadas
  {
    path: '/messenger',
    Component: lazy(() => import('pages/messenger')),
    roles: [
      'attendantbasic',
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/messenger-pipeline',
    Component: lazy(() => import('pages/messenger/pipeline-messenger')),
    roles: [
      'attendantbasic',
      'attendant',
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  {
    path: '/whats-app-link',
    Component: lazy(() => import('pages/whats-app-link')),
    exact: true,
  },
  {
    path: '/crm/pipeline',
    Component: lazy(() => import('pages/crm/pipeline')),
    exact: true,
  },
  {
    path: '/instagram/chatbot',
    Component: lazy(() => import('pages/leads/instagram/chatbot')),
    exact: true,
  },
  {
    path: '/sms/chatbot',
    Component: lazy(() => import('pages/leads/sms/chatbot')),
    roles: [
      'basic',
      'pro',
      'pro2',
      'enterprise',
      'board',
      'seller',
      'partner',
      'support',
      'facebook',
    ],
    exact: true,
  },
  // {
  //   path: '/test/widget-generator',
  //   Component: lazy(() => import('pages/widget-generator')),
  //   exact: true,
  // },
  {
    path: '/test/test',
    Component: lazy(() => import('pages/test')),
    exact: true,
  },
  // Public Pages (can be seen without login)
  {
    path: '/support/teams',
    Component: lazy(() => import('pages/teams')),
    exact: true,
  },
  {
    path: '/support/teams/:id',
    Component: lazy(() => import('pages/teams/pages/single-team')),
    exact: true,
  },
  {
    path: '/support/teams/:id/:memberId',
    Component: lazy(() => import('pages/teams/pages/single-team/pages/single-member-team')),
    exact: true,
  },
  {
    path: '/support/teams/:id/:memberId/:submemberId',
    Component: lazy(() =>
      import(
        'pages/teams/pages/single-team/pages/single-member-team/pages/single-submember-member-team'
      ),
    ),
    exact: true,
  },
  {
    path: '/public/widget-chat/:owner',
    Component: lazy(() => import('pages/public/widget-chat')),
    exact: true,
  },
  {
    path: '/public/plans',
    Component: lazy(() => import('pages/public/plans')),
    exact: true,
  },
  {
    path: '/public/checkout/:type/:plain',
    Component: lazy(() => import('pages/public/checkout')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/update-password',
    Component: lazy(() => import('pages/auth/update-password')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/maestro',
    Component: lazy(() => import('pages/UpSell/index.js')),
    exact: true,
  },
  // {
  //   path: '/maestro/cancelar',
  //   Component: lazy(() => import('pages/UpSell/cancelar.js')),
  //   roles: ['board'], // Apenas usuários com o papel 'dev' podem acessar esta rota
  //   exact: true,
  // },
  {
    path: '/thankyou',
    Component: lazy(() => import('pages/UpSell/ThankYou.js')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <ConnectionCheck />
      <Hotjar />
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact, roles }) => {
                      if (roles && roles.length > 0) {
                        return (
                          <Route
                            path={path}
                            key={path}
                            exact={exact}
                            component={props => {
                              return (
                                <ACL roles={roles} redirect="/auth/500">
                                  <Suspense fallback={<Loading />}>
                                    <Component {...props} />
                                  </Suspense>
                                </ACL>
                              )
                            }}
                          />
                        )
                      }
                      return (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          component={WaitingComponent(Component)}
                        />
                      )
                    })}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

const WaitingComponent = Component => {
  return props => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  )
}

export default connect(mapStateToProps)(Router)
