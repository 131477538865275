import { all, takeEvery, put } from 'redux-saga/effects'

import actions from './actions'

export function* SET_SELECTED_IDS(action = {}) {
  const { payload } = action

  yield put({
    type: actions.SET_STATE,
    payload: {
      selectedIds: payload.ids || [],
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_SELECTED_IDS, SET_SELECTED_IDS)])
}
