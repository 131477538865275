const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  FETCH_USER_PAYMENTS: 'user/FETCH_USER_PAYMENTS',
  UPDATE_USER: 'user/UPDATE_USER',
  FETCH_SUPERLOGICA_USER: 'user/FETCH_SUPERLOGICA_USER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  UPDATE_ACTIVITY: 'user/UPDATE_ACTIVITY',
  LOGOUT: 'user/LOGOUT',
}

export const setUserAuth = (user, userData) => {
  // console.log('setUserAuth', user, userData)
  return {
    type: actions.UPDATE_USER,
    payload: { userAuth: user, userData },
  }
}

export default actions
