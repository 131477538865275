import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import chatBot from './chatBot/reducers'
import Auth from './auth/reducers'
import Chat from './chat/reducers'
import Layout from './layout/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    chatBot,
    Auth,
    Chat,
    Layout,
  })
