import React from 'react'
import { Layout, Button, Alert } from 'antd'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ClearCache from 'react-clear-cache'
import classNames from 'classnames'
// import TopBar from 'components/cleanui/layout/TopBar'
// import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
import Footer from 'components/cleanui/layout/Footer'
// import Sidebar from 'components/cleanui/layout/Sidebar'
// import SupportChat from 'components/cleanui/layout/SupportChat'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})
const mapDispatchToProps = dispatch => ({ dispatch })

class MainLayout extends React.Component {
  render() {
    const {
      children,
      isContentMaxWidth,
      isAppMaxWidth,
      isGrayBackground,
      isSquaredBorders,
      isCardShadow,
      isBorderless,
      // isTopbarFixed,
      // isGrayTopbar,
    } = this.props

    return (
      <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
        <Layout
          className={classNames({
            cui__layout__contentMaxWidth: isContentMaxWidth,
            cui__layout__appMaxWidth: isAppMaxWidth,
            cui__layout__grayBackground: isGrayBackground,
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
          })}
        >
          {/*  <Sidebar />
        <SupportChat /> */}
          <Menu />
          <Layout>
            {/* <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
            />
          <TopBar />
            <Breadcrumbs /> */}
            <Layout.Content style={{ height: '100%', position: 'relative', marginTop: '50px' }}>
              <ClearCache duration={60 * 60 * 1000}>
                {({ isLatestVersion, emptyCacheStorage }) => (
                  <div>
                    {!isLatestVersion && (
                      <p>
                        <Alert
                          type="warning"
                          closable
                          banner
                          title="Atualiza para a última versão"
                          action={
                            <Button
                              size="small"
                              type="primary"
                              onClick={e => {
                                e.preventDefault()
                                emptyCacheStorage()
                              }}
                            >
                              Atualize agora
                            </Button>
                          }
                          description={
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault()
                                emptyCacheStorage()
                              }}
                            >
                              Você não está utilizando a versão mais recente do sistema
                            </a>
                          }
                        />
                      </p>
                    )}
                  </div>
                )}
              </ClearCache>
              <div className="cui__utils__content">{children}</div>
            </Layout.Content>
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
