import {
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  OPEN_MAESTRO_SIDEBAR,
  CLOSE_MAESTRO_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
} from './constants'

export const setActiveTab = tabId => ({
  type: SET_ACTIVE_TAB,
  payload: tabId,
})

export const openUserSidebar = () => ({
  type: OPEN_USER_PROFILE_SIDEBAR,
})

export const closeUserSidebar = () => ({
  type: CLOSE_USER_PROFILE_SIDEBAR,
})

export const openMaestroSidebar = () => ({
  type: OPEN_MAESTRO_SIDEBAR,
})

export const closeMaestroSidebar = () => ({
  type: CLOSE_MAESTRO_SIDEBAR,
})

export const setconversationNameInOpenChat = conversationName => ({
  type: SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  payload: conversationName,
})

const actions = {
  SET_ACTIVE_TAB: 'layout/SET_ACTIVE_TAB',
  // CHANGE_SETTING: 'settings/CHANGE_SETTING',
  // SET_PRIMARY_COLOR: 'settings/SET_PRIMARY_COLOR',
  // SET_THEME: 'settings/SET_THEME',
}

export default actions
